package nl.icsvertex.web.homescreen.ui

import js.promise.await
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import kotlinx.serialization.json.Json
import mui.material.Box
import mui.material.CssBaseline
import mui.system.ThemeProvider
import mui.system.sx
import nl.icsvertex.web.homescreen.data.Application
import nl.icsvertex.web.homescreen.ui.components.ApplicationTile
import react.FC
import react.dom.html.ReactHTML
import react.useEffectOnce
import react.useState
import web.cssom.*
import web.http.fetch

val HomeScreen = FC("ICS_HomeScreen") {
    var applicationList by useState<List<Application>>(listOf())

    useEffectOnce {
        MainScope().launch {
            val applicationResponse = fetch("/applications.json")

            applicationList = Json.decodeFromString<List<Application>>(
                applicationResponse.text().await()
            )
        }
    }

    ThemeProvider {
        CssBaseline()

        // ICS Logo
        Box {
            sx {
                position = Position.absolute
                width = 100.pct
                display = Display.flex
                justifyContent = JustifyContent.center
                alignItems = AlignItems.center
                marginTop = 8.px
            }

            ReactHTML.img {
                src = "/static/images/ics_logo.png"
                width = 300.0
            }
        }

        Box {
            sx {
                backgroundImage = url("/static/images/background.png")
                backgroundRepeat = BackgroundRepeat.noRepeat
                backgroundSize = BackgroundSize.cover
                height = 100.pct
                maxHeight = 100.pct
                width = 100.pct
                padding = Padding(9.px, 8.px, 8.px)
                overflowY = Auto.auto
                display = Display.flex
                justifyContent = JustifyContent.center
                alignItems = AlignItems.center
            }

            Box {
                sx {
                    width = Length.fitContent
                    display = Display.flex
                    justifyContent = JustifyContent.spaceEvenly
                    alignItems = AlignItems.center
                    flexWrap = FlexWrap.wrap
                    gap = 16.px
                }

                applicationList.forEach {
                    ApplicationTile {
                        applicationName = it.name
                        applicationEnvironment = it.environment
                        applicationIcon = it.icon
                        applicationHref = it.url
                    }
                }
            }
        }
    }
}