package nl.icsvertex.web.homescreen

import nl.icsvertex.web.homescreen.ui.HomeScreen
import react.create
import react.dom.client.createRoot
import web.dom.document

fun main() {
    val body = document.body
    val container = document.createElement("root")
    val firstChild = body.firstChild
    body.insertBefore(container, firstChild)
    createRoot(container).render(HomeScreen.create())
}