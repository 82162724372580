package nl.icsvertex.web.homescreen.ui.components

import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.dom.html.ReactHTML
import web.cssom.*
import web.location.location
import web.window.WindowTarget
import web.window.window

external interface ApplicationTileProps : Props {
    var applicationName: String
    var applicationEnvironment: String
    var applicationIcon: String
    var applicationHref: String
}

val ApplicationTile = FC<ApplicationTileProps>("Application_Tile") { props ->
        Card {
            sx {
                minWidth = 245.px
            }
            CardActionArea {
                onClick = {
                    window.open(props.applicationHref, WindowTarget._blank)
                }
                CardMedia {
                    component = ReactHTML.img
                    src = props.applicationIcon
                    sx {
                        padding = 8.px
                        height = 140.px
                        width = 100.pct
                        objectFit = ObjectFit.contain
                    }
                }
                CardContent {
                    sx {
                        display = Display.flex
                        flexFlow = FlexFlow.column
                        justifyContent = JustifyContent.center
                        alignItems = AlignItems.center
                    }
                    Typography {
                        variant = TypographyVariant.h3
                        +props.applicationName
                    }
                    Chip {
                        label = ReactNode(props.applicationEnvironment)
                        color = when{
                            props.applicationEnvironment.uppercase().contains("PRODUCTION") -> ChipColor.primary
                            props.applicationEnvironment.uppercase().contains("TEST") -> ChipColor.warning
                            props.applicationEnvironment.uppercase().contains("DEVELOPMENT") -> ChipColor.error
                            else -> ChipColor.default
                        }
                        variant = ChipVariant.filled
                        sx {
                            margin = 4.px
                        }
                    }
//                    Typography {
//                        variant = TypographyVariant.subtitle1
//                        +props.applicationEnvironment
//                    }
                }
            }
    }
}